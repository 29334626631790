import React, { Fragment } from "react";
import Main from "../../components/main/Main.component";
import News from "../../components/news/news.component";
import SearchComponent from "../../components/opac-search/opacSearch.component";
import SmallBanner from "../../components/smallBanner/smallBanner.component";

/****   Assets ******/
import id_card from "../../assets/images/banner/id_card.svg";
import mobile from "../../assets/images/banner/mobile_icon.svg";
import IMAGES from "../../components/imagesVariable/imagesVariable";
// import bnCard from "../../assets/images/banner/banner_id_card.svg";
// import ithenticate from "../../assets/images/download.png";
// import openathen from "../../assets/images/Openathence.png";
// import repository from "../../assets/images/repository.jpg";
const allResources = [
  {
    img: IMAGES.emerald,
    name: "Emerald Insight",
    link: "https://www.emerald.com/insight/",
  },
  // {
  //   img: IMAGES.jstor,
  //   name: "JSTOR",
  //   link: "https://www.jstor.org/",
  // },
  {
    img: IMAGES.projectMuse,
    name: "Project Muse",
    link: "https://muse.jhu.edu/",
  },
  {
    img: IMAGES.springer,
    name: "Springer Link",
    link: "https://link.springer.com/",
  },
  {
    img: IMAGES.ebsco,
    name: "EBSCO",
    link: "https://www.ebsco.com/",
  },
  {
    img: IMAGES.bookYourSlot,
    name: "Booking slot for 1st year student.",
    link: "https://jahangirnagar-university1.odoo.com/book/d3f6f906",
  },
  {
    img: IMAGES.citizensCharter,
    name: "Citizen's Charter",
    link: "/citizen_charter",
  },

  {
    img: IMAGES.idCard,
    name: "Id Card",
    link: "https://devpis.juniv.edu//",
  },

  {
    img: IMAGES.remote,
    name: "Remote Access",
    link: "https://my.openathens.net/?passiveLogin=false",
  },
  {
    img: IMAGES.thesis2,
    name: "Thesis Submission",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSeKnyU7XnzNpdcAPRe8XflJPbRL2GUg4N49nbJbDsM3Tu_rnw/viewform",
  },
];



const Home = () => (
  <Fragment>
  <SearchComponent />
    <Main />
    {/* <LogoSlider
    items={allResources}
    />*/}
    <SmallBanner
      logo={id_card}
      title="Get a Library Card"
      description="Access all of the great resources that the library has to offer!"
      buttonText="Please collect Library Card from concerned hall office"
      backgroundColor="linear-gradient(135deg, #1a237e, #272d76)"
      weblink="#"
    />
    <News />
    {/* 
    <TitleHeader
      firstTitlePart="Library"
      secondTitlePart="Services"
      primaryCLR="#673AB7"
      secondaryCLR="#9c27b0"
      borderClR="#1867a5"
      shortDescription="Library services provide access to books, digital resources, research support and community programs."
    />
    <StylishCard cards={cardData1} bannerBackgroundColor="#fefefe" /> 
    <SmallBanner
      logo={news}
      title="Get University Id Card"
      description="University ID Card: Access, Benefits, and Security"
      buttonText="Get Id Card"
      backgroundColor="#00AB77"
      weblink="https://devpis.juniv.edu//"
    />
    
    <TitleHeader
    firstTitlePart="Open Access"
    secondTitlePart=" Resources"
    primaryCLR="#673AB7"
    secondaryCLR="#9c27b0"
    borderClR="#1867a5"
    shortDescription="Library services provide access to books, digital resources, research support and community programs."
  />
  <StylishCard cards={cardData1} bannerBackgroundColor="#fefefe" />
   

   <SruSearch />

   <Collaborations />
   
  */} 

  
   <SmallBanner
      logo={mobile}
      title="Download the JU Library App"
      description="The App is in under process"
      buttonText="Get the app"
      backgroundColor="linear-gradient(135deg, #1a237e, #272d76)"
      weblink="https://play.google.com/store/apps/details?id=com.digitalsolutionbd.julibraryapp"
    />
    {/* 
   
    <TitleHeader
    firstTitlePart="New"
    secondTitlePart="Arrivals"
    primaryCLR="#673AB7"
    secondaryCLR="#9c27b0"
    borderClR="#1867a5"
    shortDescription="Library services provide access to books, digital resources, research support and community programs."
  />
    <StylishCard cards={cardData1} bannerBackgroundColor="#fefefe" />*/}
   
  </Fragment>
);

export default Home;
