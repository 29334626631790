// Event.js
import React from 'react';
import EventItem from '../eventItem/eventItem.component';
import './Event.style.scss';

const Event = ({ events , Title }) => {
  return (
    <div className="event" style={{ marginLeft: '15px' }}>
      <h2>{Title}</h2>
      <div className="event-list">
        {events.map((event, index) => (
          <EventItem
            key={index}
            libraryName={event.name}
            libraryLogo={event.img}
            libraryLink={event.link}
            blinking={event.isBlink}
          />
        ))}
      </div>
      {Title === 'Digital Resources' && (
        <a href='https://juniv.edu/office/university-library/program/8253' target='_blank' rel='noreferrer'>
          View all {Title} &gt;
        </a>
      )}
      </div>
  );
};

export default Event;
