import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react';
import IMAGES from '../imagesVariable/imagesVariable';
import './Footer.style.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer__content">
      <div className="footer__sections">
        <div className="footer__section">
          <h3>About</h3>
          <ul>
            <li><a href="#mission">Mission & Vision</a></li>
            <li><a href="/library_hours">Hours & Location</a></li>
            <li><a href="/privacy_policy">Policies</a></li>
            <li><a href="#about">About the Library</a></li>
          </ul>
        </div>
        <div className="footer__section">
          <h3>Resources</h3>
          <ul>
            <li><a href="#catalog">Catalog</a></li>
            <li><a href="#classic">Classic Catalog</a></li>
            <li><a href="#digital">Digital Library</a></li>
            <li><a href="#events">Ju Journals</a></li>
            <li><a href="#cards">Institutional Repository</a></li>
          </ul>
        </div>
        <div className="footer__section">
          <h3>Help</h3>
          <ul>
            <li><a href="#ask">Ask A Librarian</a></li>
            <li><a href="#faqs">FAQs</a></li>
            <li><a href="#account">Online Registration</a></li>
          </ul>
        </div>
      </div>
      <div className="footer__contact">
      <img src={IMAGES.songsoptok} alt='' />
        <button className="footer__contact-button">Contact Us</button>
        <div className="footer__contact-info">
          <strong>University Library, Jahangirnagar University</strong><br />
          Savar, Dhaka-1342<br />
          <a href="tel:02224491045">02224491045-51 (Ext.1244, 2275)</a><br />
          <a href="mailto:library@juniv.edu">library@juniv.edu</a>
        </div>
        <br />
        <div className="footer__social">
        <a href="https://www.facebook.com/p/University-Library-JU-100064702583896/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
        <a href="https://x.com/library_JUniv" target="_blank" rel="noopener noreferrer"><i className="fab fa-x"></i></a>
        <a href="https://www.instagram.com/library_juniv/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
        <a href="mailto:library@juniv.edu"><i className="fas fa-envelope"></i></a>
      </div>
      
      </div>
    </div>
    <div className="footer__bottom">
      <p>© 2024 University Library, Jahangirnagar University</p>
    </div>
  </footer>
);

export default Footer;
