import React from 'react';
import IMAGES from '../imagesVariable/imagesVariable';
import './CitizenCharter.style.scss';
const CitizenCharter = () => {
    const specialPeopleData = [
        {
          id: 1,
          name: 'Dr. Abu Sayed Md. Mostafizur Rahaman',
          role: 'Teacher-in-Charge',
          img: IMAGES.muftafiz, 
          link: 'asmmr@juniv.edu',
          
        },
        {
          id: 2,
          name: 'M Salahuddin Bhuiya',
          role: 'Additional Teacher-in-Charge',
          img: IMAGES.salahuddin,
          link: 'salahuddin1976@juniv.edu',
        },
        {
          id: 3,
          name: 'Mohammad Ashraful Islam',
          role: 'Deputy Teacher-in-Charge',
          img: IMAGES.ashraful,
          link: 'ashraful.islam@juniv.edu',
        }
      ];
    
      const cardData = [
        {
          serviceName: "বই, গ্রন্থাগার সাময়িক ইস্যু এবং জমাদান",
          serviceMethod: "গ্রন্থাগার কার্ড প্রদানের মাধ্যমে",
          serviceTime: "Sun-Tue (7.30 AM - 9.30 PM)",
          officer: {
            name: "Md. Faruk Hossain",
            position: "Technical Officer(Library)",
            phone: "01935928761",
            email: "faruk@juniv.edu",
            img: IMAGES.profileDemo,
          },
        },
        {
          serviceName: "প্রবেশপত্র সেবা, গবেষণা ও সংবাদ পত্র",
          serviceMethod: "গ্রন্থাগার কার্ড প্রদানের মাধ্যমে",
          serviceTime: "Sun-Tue (7.30 AM - 9.30 PM)",
          officer: {
            name: "Shimul Kumar Barua",
            position: "Technical Officer(Library)",
            phone: "01731511729",
            email: "shimul.lib@juniv.edu",
            img: IMAGES.profileDemo,
          },
        },
        {
          serviceName: "পাঠক সেবা",
          serviceMethod: "গ্রন্থাগার কার্ড প্রদানের মাধ্যমে",
          serviceTime: "Sun-Tue (7.30 AM - 9.30 PM)",
          officer: {
            name: "Md. Badrul Alam",
            position: "Deputy Registrar ",
            phone: "01922803361",
            email: "badrulalam.lib@juniv.edu",
            img: IMAGES.profileDemo,
          },
        },
        {
          serviceName: "ক্যাটালগ, পুস্তক সংরক্ষণ ও অনলাইন সেবা",
          serviceMethod: "চাহিদা মোতাবেক",
          serviceTime: "Sun-Tue (7.30 AM - 9.30 PM)",
          officer: {
            name: "Md. Tauhidul Islam",
            position: "Deputy Librarian",
            phone: "01866371854",
            email: "tauhidul.lib@juniv.edu",
            img: IMAGES.profileDemo,
          },
        },
      ];
    
      return (
        <div className="card-layout-container">
          
    
          {/* Special People Section */}

          <div className="special-people">
            {specialPeopleData.map((person, index) => (
              <div key={person.id} className={`special-person ${
        specialPeopleData.length % 2 !== 0 && index === 0 ? "centered" : ""
      }`}>
                <div className="profile">
                  <img src={person.img} alt={`Profile of ${person.name}`} />
                </div>
                <div className="person-details">
                  <h3>{person.name}</h3>
                  <p>{person.role}</p>
                  <a href={person.link}>{person.link}</a>
                  
                </div>
              </div>
            ))}
          </div>
    
          {/* Horizontal Cards Section */}
          <div className="card-container_CC">
            {cardData.map((card, index) => (
              <div key={index} className="card_CC">
                <div className="profile">
                  <img src={card.officer.img} alt={`Profile of ${card.officer.name}`} />
                </div>
                <div className="card-details">
                  <h4>{card.serviceName}</h4>
                  <p>Service Method: {card.serviceMethod}</p>
                  <p>Service Time: {card.serviceTime}</p>
                  <p>Officer: {card.officer.name}</p>
                  <p>{card.officer.position}</p>
                  <a href={`tel:${card.officer.phone}`}>{card.officer.phone}</a>
                 <br />
                  <a href={`mailto:${card.officer.email}`}>{card.officer.email}</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    };
    
export default CitizenCharter;
