import React from "react";
import "./MostPopular.style.scss";

const MostPopular = ({popularItems}) => {
  

  return (
    <section className="most-popular">
      <h2 className="most-popular__title">Most Popular</h2>
      <div className="most-popular__grid">
        {popularItems.map((item, index) => (
          <a href={item.link} key={index} className="most-popular__card">
            <div className="most-popular__img-wrapper">
              <img src={item.img} alt={item.title} className="most-popular__img" />
            </div>
            <p className="most-popular__name">{item.title}</p>
          </a>
        ))}
      </div>
    </section>
  );
};

export default MostPopular;
