import { Flex } from "antd";
import React from "react";
import ButtonStyle01 from "../button-style01/ButtonStyle01.component";
import Event from "../event/Events.component";
import ImageCarousel from "../image-carousel/image-carousel.component";
import "./Main.styles.scss";

/***   Assets ****/
import IMAGES from "../imagesVariable/imagesVariable";
/*
const digitalResources = [
  {
    img: IMAGES.emerald,
    name: "Emerald Insight",
    link: "https://www.emerald.com/insight/",
  },
  // {
  //   img: IMAGES.jstor,
  //   name: "JSTOR",
  //   link: "https://www.jstor.org/",
  // },
  {
    img: IMAGES.projectMuse,
    name: "Project Muse",
    link: "https://muse.jhu.edu/",
  },
  {
    img: IMAGES.springer,
    name: "Springer Link",
    link: "https://link.springer.com/",
  },
  {
    img: IMAGES.ebsco,
    name: "EBSCO",
    link: "https://www.ebsco.com/",
  },
];

*/

const quickLinks = [
  {
    img: IMAGES.bookYourSlot,
    name: "Booking slot for 1st year student.",
    link: "https://jahangirnagar-university1.odoo.com/book/d3f6f906",
    isBlink: true
  },
  {
    img: IMAGES.citizensCharter,
    name: "Citizen's Charter",
    link: "/citizen_charter",
    isBlink: false
  },

  {
    img: IMAGES.idCard,
    name: "Id Card",
    link: "https://devpis.juniv.edu//",
    isBlink: false
  },

  {
    img: IMAGES.remote,
    name: "Remote Access",
    link: "https://my.openathens.net/?passiveLogin=false",
    isBlink: false
  },
  {
    img: IMAGES.thesis2,
    name: "Thesis Submission",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSeKnyU7XnzNpdcAPRe8XflJPbRL2GUg4N49nbJbDsM3Tu_rnw/viewform",
    isBlink: false
  },
];

const Main = () => (
  <main>
    <div>
      <Flex className="responsive-flex">
        <div className="responsive">
          <Event events={quickLinks} Title={"Quick Links"} />
        </div>

        <ImageCarousel
          images={[
            {
              url: IMAGES.banner4
            },
            {
              url: IMAGES.banner1,
            },
            {
              url: IMAGES.banner2,
            },
            {
              url: IMAGES.banner3,
            }
            
          ]}
        />
        <div>
        {/*   <Event events={digitalResources} Title="Digital Resources" />
        */}

        <ButtonStyle01
        buttonText="View All Digital Resources"
        buttonLink="/a-z-database"
        />
        <br />
          </div>
      </Flex>
    </div>
  </main>
);

export default Main;
