import React, { Fragment } from 'react';
import HorizontalBox from '../../components/horizontal-box/HorizontalBox.component';
import IMAGES from '../../components/imagesVariable/imagesVariable';
import MostPopular from '../../components/most-popular/MostPopular.component';
import PageTitleBanner from '../../components/page-title-banner/PageTitleBanner.component';

const allData = [
  {
    id: 1,
    
    title: "American Institute of Physics (AIP)",
    link: "https://aip.scitation.org/",
    description: "AIP Publishing’s portfolio comprises 19 highly regarded, peer-reviewed journals, including the flagship journals Applied Physics Letters, Journal of Applied Physics, and The Journal of Chemical Physics, in addition to the AIP Conference Proceedings."
  },
  {
    id: 2,
    
    title: "American Physical Society (APS)",
    link: "https://journals.aps.org/about",
    description: "Physical Review Letters (PRL) is the premier APS journal for current research, providing rapid publication of short reports of important fundamental research in all fields of physics. PRL provides its diverse readership with weekly coverage of major advances in physics and cross-disciplinary developments."
  },
  {
    id: 3,
    
    title: "American Society of Agricultural and Biological Engineers (ASABE)",
    link: "https://elibrary.asabe.org/",
    description: "The American Society of Agricultural and Biological Engineers (ASABE) is an international professional society devoted to agricultural and biological engineering. ASABE serves many functions: it provides a forum for communication of research findings through conferences, scientific journals, and a magazine."
  },
  {
    id: 4,
    
    title: "American Society of Civil Engineers (ASCE)",
    link: "https://www.asce.org/",
    description: "ASCE is a leading provider of technical and professional conferences and continuing education, the world’s largest publisher of civil engineering content."
  },
  {
    id: 5,
    
    title: "Annual Reviews",
    link: "https://www.annualreviews.org/",
    description: "Annual Reviews is a publisher dedicated to synthesizing and integrating knowledge for the progress of science. It has a collection of 46 review series in specific disciplines in science and social science. Each review series contains 12 to 40 authoritative comprehensive review articles, covering the major journal articles on a specific topic during the preceding few years."
  },
  {
    id: 6,
    
    title: "British Institute of Radiography",
    link: "https://www.bir.org.uk/",
    description: "The British Institute of Radiology is the international membership organisation for everyone working in imaging and radiation oncology."
  },
  {
    id: 7,
    
    title: "Cambridge University Press (CUP)",
    link: "https://www.cambridge.org/",
    description: "Cambridge University Press is a department of the University of Cambridge and is both an academic and educational publisher. It publishes over 50,000 titles by authors from over 100 countries, including academic journals, monographs, reference works, textbooks, and English language teaching and learning publications."
  },
  {
    id: 8,
    
    title: "Canadian Science Publishing",
    link: "https://www.nrcresearchpress.com/",
    description: "Canadian Science Publishing (CSP) is Canada’s largest publisher of international scientific journals. CSP currently owns and publishes 24 diverse international journals with distribution in more than 175 countries, and provides publishing services to an additional 15 titles."
  },
  {
    id: 9,
    
    title: "Cochrane Library",
    link: "https://www.cochranelibrary.com/",
    description: "The Cochrane Library is a collection of databases that contain different types of high-quality, independent evidence to inform healthcare decision-making. The Cochrane Library is owned by Cochrane and published by Wiley. It is also available in a Spanish language version."
  },
  {
    id: 10,
    
    title: "De Gruyter LIS Books",
    link: "https://www.degruyter.com/browse?type_0=books",
    description: "De Gruyter Library and Information Science Books: Offers 45 e-books."
  },
  {
    id: 11,
    
    title: "EBSCO including CMMC",
    link: "http://web.a.ebscohost.com/ehost/search/selectdb?vid=0&sid=4e29b8c1-d1f1-4ef2-8407-5fcc3f18d136@sdc-v-sessmgr04",
    description: "EBSCO division of EBSCO Industries Inc. EBSCO offers library resources to customers in academic, medical, K–12, public library, law, corporate, and government markets. EBSCOhost supplies a fee-based online research service with 375 full-text databases, and a collection of 600,000-plus ebooks."
  },
  {
    id: 12,
    
    title: "Edinburgh University Press",
    link: "https://www.euppublishing.com/",
    description: "Edinburgh University Press publishes a range of research publications, including scholarly monographs and reference works, textbooks for students and lecturers, around 205 books, and 42 journals each year. The press mainly publishes in humanities and social sciences."
  },
  {
    id: 13,
    
    title: "Geological Society",
    link: "https://www.geolsoc.org.uk/",
    description: "The Geological Society of London publishes two of its own journals, the Journal of the Geological Society and the Quarterly Journal of Engineering Geology and Hydrogeology. It also publishes the magazine Geoscientist for Fellows and has a share in Geology Today."
  },
  {
    id: 14,
    
    title: "IEEE",
    link: "https://ieeexplore.ieee.org/search/searchresult.jsp?newsearch=true&queryText=robotics",
    description: "The IEEE Xplore digital library is gateway to trusted research—journals, conferences, standards, ebooks, and educational courses—with more than 4.5 million documents."
  },
  {
    id: 15,
    
    title: "Indian Journals",
    link: "http://www.indianjournals.com/ijor.aspx",
    description: "IndianJournals.com is a vast collection of interdisciplinary Indian Journals and Research Publications."
  },
  {
    id: 16,
    
    title: "Mary Ann Liebert",
    link: "https://www.liebertpub.com/",
    description: "Mary Ann Liebert, Inc. is a leading independent publisher known worldwide for its prescience and establishment of authoritative peer-reviewed journals, books, and trade publications in cutting-edge fields such as biotechnology and regenerative medicine, biomedical research, medicine and surgery, public health research and policy, technology and engineering, law, environmental research and policy, and other specialized disciplines."
  },
  {
    id: 17,
    
    title: "Nature - Nature Research Journals",
    link: "https://www.springernature.com/gp/librarians/products/product-types/journals/nature-research-journals",
    description: "Nature Publishing Group (NPG) is an international publishing company that publishes academic journals, magazines, online databases, and services in science and medicine. It also publishes Nature research journals, Nature Reviews journals (since 2000), and society-owned academic journals."
  },
  {
    id: 18,
    
    title: "Nature - Palgrave Macmillan Journals",
    link: "https://www.palgrave.com/gp/journals",
    description: "As part of Springer Nature, Palgrave Macmillan continues to uphold its reputation as a dynamic journal publisher. Scholarly journals offering reliable, thought-provoking academic content across business & economics, humanities, and the social sciences."
  },
  {
    id: 19,
    
    title: "Nature - Academic Journals",
    link: "https://www.springernature.com/gp/librarians/products/product-types/journals/academic-journals-on-nature-com",
    description: ""
  },
  {
    id: 20,
    
    title: "Optical Society of America (OSA)",
    link: "https://www.osa.org/en-us/home/",
    description: "OSA Publishing is the largest peer-reviewed collection of optics and photonics information in the world. It publishes journals, and organizes conferences and exhibitions."
  },
  {
    id: 21,
    
    title: "Policy Press",
    link: "https://bristoluniversitypress.co.uk/",
    description: "Policy Press, established in 1996, is an academic publisher based in the Faculty of Social Sciences and Law at the University of Bristol, UK. The Policy Press is a leading international publisher of books and journals across a wide range of social science subjects. Policy Press publishes 120 new books each year and has a backlist of over 600 books."
  },
  {
    id: 22,
    
    title: "Project MUSE books",
    link: "http://resources.library.ubc.ca/65",
    description: "2010-2017 collections already purchased (17,000+ titles)"
  },
  {

    id:23,
    title: " Project MUSE Journals",
    link: "https://muse.jhu.edu/",
    description:"Project MUSE provides online access to almost 500 full-text journals from 140 non-profit publishers in the humanities and social sciences."
  },
  {
    id:24,
    title: "Research4Life",
    link: "https://www.research4life.org/",
    description:"Research4Life is the collective title for five programmes – Hinari, AGORA, OARE, ARDI and GOALI – that provide developing countries with free or low-cost access to academic and professional peer-reviewed content online."
  },
  {
    id:25,
    title: "Royal College of Physicians",
    link: "https://www.rcplondon.ac.uk/",
    description: "The Royal College of Physicians is a British professional body dedicated to improving the practice of medicine, chiefly through the accreditation of physicians by examination. Clinical Medicine is the college's own medical journal. It appears once every two months. In addition, the college publishes regular reports, clinical guidelines, and the annual series of College reviews."
  },
  {
    id: 26,
    type: "publisher",
    title: "Royal Society",
    link: "https://royalsociety.org/",
    description: "The society introduced the world's first journal exclusively devoted to science in 1665, Philosophical Transactions, and in so doing originated the peer review process now widespread in scientific journals. Its founding editor was Henry Oldenburg, the society's first secretary. Through Royal Society Publishing, the society publishes 11 journals. Philosophical Transactions is the oldest and longest-running scientific journal in the world, having first been published in March 1665 by the first secretary of the society, Henry Oldenburg."
  },
  {
    id: 27,
    type: "publisher",
    title: "Scientific American",
    link: "https://www.scientificamerican.com/",
    description: "Scientific American is a popular science magazine that provides insights and updates on various scientific fields."
  },
  {
    id: 28,
    type: "publisher",
    title: "SPIE Digital Library",
    link: "https://www.spiedigitallibrary.org/?SSO=1",
    description: "The SPIE (Society of Photo-Optical Instrumentation Engineers) Digital Library is the most extensive resource available on optics and photonics, providing unprecedented access to more than 270,000 technical papers from SPIE Journals and Conference Proceedings from 1990 to the present. More than 17,000 new technical papers are added annually."
  },
  {
    id: 29,
    type: "publisher",
    title: "Springer ALL (2000+ titles)",
    link: "https://www.springer.com/gp/",
    description: "Springer is one of the leading international scientific publishing companies. Springer's eJournals cover a wide range of subjects including biomedicine and the life sciences, clinical medicine, physics, engineering, mathematics, computer sciences, human sciences, social sciences, and economics."
  },
  {
    id: 30,
    type: "publisher",
    title: "Springer - Adis Collection",
    link: "https://www.springer.com/gp/adis",
    description: "The Adis Collection from Springer focuses on drug development and clinical research publications."
  },
  {
    id: 31,
    type: "publisher",
    title: "Wiley Online Library",
    link: "https://onlinelibrary.wiley.com/",
    description: "Continued access only to 2018 content confirmed."
  },
  {
    id: 32,
    type: "publisher",
    title: "Wiley Online Library",
    link: "https://onlinelibrary.wiley.com/",
    description: "Wiley InterScience is a leading international resource for scientific, technical, medical, and scholarly content providing access to millions of articles across a wide range of journals. This package provides access to over 350 journals."
  }
];
const popularList = [
  { title: "Emerald Insight", link: "https://www.emerald.com/insight/", img: IMAGES.emerald },
  { title: "JSTOR", link: "https://www.jstor.org/", img: IMAGES.jstor },
  { title: "Springer Link", link: "https://link.springer.com/", img: IMAGES.springer },
  { title: "EBSCO", link: "https://www.ebsco.com/", img: IMAGES.ebsco },
  { title: "Projec Muse", link: "https://muse.jhu.edu/", img: IMAGES.projectMuse },
];

const AtoZDatabase = () => (
  <Fragment>
    <PageTitleBanner 
      backgroundImage={IMAGES.banner3}
      titleText="- A to Z Database -"
    />

    <MostPopular
    popularItems={popularList}
    />
    
    <HorizontalBox 
      bannerData={allData}
    />
  </Fragment>
);

export default AtoZDatabase;
