import React from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/Footer.component';
import Header from './components/header/Header.component';
import AtoZDatabase from './pages/AtoZDatabase/AtoZDatabase.page';
import CitizenCharterPage from './pages/citizenCharter/citizenCharter.page';
import Home from './pages/home/Home.page';
import LibraryHourPage from './pages/libraryHours/libraryHours.page';
import NoticePage from './pages/notice_board/noticeBoard.page';
import PrivacyPolicyPage from './pages/privacyPolicy/privacyPolicy.page';
import QuickLinks from './pages/quickLinks/QuickLinks.page';
import Services from './pages/services/Services.page';
import ThesisList from './pages/thesis/thesisList.page';

const RedirectTo = ({ url }) => {
  window.location.href = url;
  return null;
};

const App = () => {
  const location = useLocation();

  const showHeader = location.pathname.startsWith('/app');

  return (
    <div className="app">
      {!showHeader && <Header />}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/quick_links" element={<QuickLinks />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/a-z-database" element={<AtoZDatabase />} />
        <Route exact path="/thesis_list" element={<ThesisList />} />
        <Route exact path="/notices" element={<NoticePage />} />
        <Route exact path="/library_hours" element={<LibraryHourPage />} />
        <Route exact path="/citizen_charter" element={<CitizenCharterPage />} />
        <Route path="/remote_access_login" element={<RedirectTo url="https://my.openathens.net/?passiveLogin=false" />} />
        <Route path="/remote_access_registration" element={<RedirectTo url="https://my.openathens.net/?passiveLogin=false" />} />
        <Route path="/plagiarism" element={<RedirectTo url="https://www.ithenticate.com/" />} />
        <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />

        {/* for Mobile app routes */}
        <Route exact path="/app" element={<Home />} />
        <Route exact path="/app/quick_links" element={<QuickLinks />} />
        <Route exact path="/app/services" element={<Services />} />
        <Route exact path="/app/a-z-database" element={<AtoZDatabase />} />
        <Route exact path="/app/thesis_list" element={<ThesisList />} />
        <Route exact path="/app/notices" element={<NoticePage />} />
        <Route exact path="/app/library_hours" element={<LibraryHourPage />} />
        <Route exact path="/app/citizen_charter" element={<CitizenCharterPage />} />
        <Route path="/app/remote_access_login" element={<RedirectTo url="https://my.openathens.net/?passiveLogin=false" />} />
        <Route path="/app/remote_access_registration" element={<RedirectTo url="https://my.openathens.net/?passiveLogin=false" />} />
        <Route path="/app/plagiarism" element={<RedirectTo url="https://www.ithenticate.com/" />} />
        <Route path="/app/privacy_policy" element={<PrivacyPolicyPage />} />
      </Routes>
      <Footer />
    </div>
  );
};

const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;
